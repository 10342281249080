import { VoivodeshipNames } from "../Imports/Enums"

export const mockVoivodeships = [
  {
    api_id: 6695624,
    capital: "Warszawa",
    lat: 52.237049,
    lon: 21.017532,
    name: "Mazowieckie",
    slug: VoivodeshipNames.MAZOWIECKIE,
  },
  {
    api_id: 7530858,
    capital: "Poznań",
    lat: 52.409538,
    lon: 16.931992,
    name: "Wielkopolskie",
    slug: VoivodeshipNames.WIELKOPOLSKIE,
  },
  {
    api_id: 765876,
    capital: "Lublin",
    lat: 51.246452,
    lon: 22.568445,
    name: "Lubelskie",
    slug: VoivodeshipNames.LUBELSKIE,
  },
  {
    api_id: 763166,
    capital: "Olsztyn",
    lat: 53.770226,
    lon: 20.490189,
    name: "Warmińsko-mazurskie",
    slug: VoivodeshipNames.WARMINSKO_MAZURSKIE,
  },
  {
    api_id: 3083829,
    capital: "Szczecin",
    lat: 53.428543,
    lon: 14.552812,
    name: "Zachodnio-pomorskie",
    slug: VoivodeshipNames.ZACHODNIO_POMORSKIE,
  },
  {
    api_id: 776069,
    capital: "Białystok",
    lat: 53.1324886,
    lon: 23.1688403,
    name: "Podlaskie",
    slug: VoivodeshipNames.PODLASKIE,
  },
  {
    api_id: 3081368,
    capital: "Wrocław",
    lat: 51.1078852,
    lon: 17.0385376,
    name: "Dolnośląskie",
    slug: VoivodeshipNames.DOLNOSLASKIE,
  },
  {
    api_id: 3099434,
    capital: "Gdańsk",
    lat: 54.352025,
    lon: 18.646638,
    name: "Pomorskie",
    slug: VoivodeshipNames.POMORSKIE,
  },
  {
    api_id: 3337493,
    capital: "Łódź",
    lat: 51.7592485,
    lon: 19.4559833,
    name: "Łódzkie",
    slug: VoivodeshipNames.LODZKIE,
  },
  {
    api_id: 3102014,
    capital: "Bydgoszcz",
    lat: 53.1234804,
    lon: 18.0084378,
    name: "Kujawsko-pomorskie",
    slug: VoivodeshipNames.KUJAWSKO_POMORSKIE,
  },
  {
    api_id: 759734,
    capital: "Rzeszów",
    lat: 50.0411867,
    lon: 21.9991196,
    name: "Podkarpackie",
    slug: VoivodeshipNames.PODKARPACKIE,
  },
  {
    api_id: 3094802,
    capital: "Kraków",
    lat: 50.0646501,
    lon: 19.9449799,
    name: "Małopolskie",
    slug: VoivodeshipNames.MALOPOLSKIE,
  },
  {
    api_id: 3080165,
    capital: "Zielona Góra",
    lat: 51.9356214,
    lon: 15.5061862,
    name: "Lubuskie",
    slug: VoivodeshipNames.LUBUSKIE,
  },
  {
    api_id: 769250,
    capital: "Kielce",
    lat: 50.8660773,
    lon: 20.6285677,
    name: "Świętokrzyskie",
    slug: VoivodeshipNames.SWIETOKRZYSKIE,
  },
  {
    api_id: 3096472,
    capital: "Katowice",
    lat: 50.2648919,
    lon: 19.0237815,
    name: "Śląskie",
    slug: VoivodeshipNames.SLASKIE,
  },
  {
    api_id: 3090048,
    capital: "Opole",
    lat: 50.6751067,
    lon: 17.9212976,
    name: "Opolskie",
    slug: VoivodeshipNames.OPOLSKIE,
  },
]

export default mockVoivodeships
